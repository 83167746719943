import Vue from "vue";
import Vuelidate from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from "vuelidate";

Vue.use(Vuelidate);

var vm = new Vue({
    el: "#securityApp",
    mixins: [
        validationMixin
    ],
    data: function () {
        return {
            username: null,
            password: null,
            rememberMe: false,
            errorMessage: null
        }
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    },
    methods: {
    }
});
